<template>
    <div class="flex justify-start h-full">
        <NuxtLoadingIndicator color="repeating-linear-gradient(to right, #DFE7EC 0%, #153F62 100%)" />
        <MenuMobile />
        <div
            class="hidden relative md:flex flex-col h-full bg-gray-100 p-4 shadow-lg border-r border-r-gray-200 transition-all"
            :class="toggleMenu ? 'w-80' : 'w-20'"
        >
            <div
                class="absolute flex justify-center items-center rounded-full font-bold cursor-pointer w-6 h-6 bg-white shadow-md top-5 -right-3 select-none"
                @click="toggleMenu = !toggleMenu"
            >
                <span class="material-symbols-outlined transition-all delay-200 z-10" :class="{ 'rotate-180': !toggleMenu }">
                    chevron_left
                </span>
            </div>

            <div class="flex items-center text-2xl mb-12">
                <img src="@/assets/logo.svg" alt="Transo" width="40" />
                <span v-if="toggleMenu" class="ml-4 select-none">Transo.</span>
            </div>

            <MenuDesktop />
        </div>
        <div class="w-full h-full">
            <div class="flex justify-end items-center px-4 w-full h-16 border-b text-sm">
                <span class="mr-4">{{ user?.email }}</span>
                <span>
                    <NuxtLink :to="switchLocalePath('pl')">PL</NuxtLink> |
                    <NuxtLink :to="switchLocalePath('en')">EN</NuxtLink>
                </span>
            </div>
            <main class="relative h-[calc(100%-64px)] px-4 pb-20 sm:px-6 lg:px-8 overflow-y-scroll">
                <slot />
            </main>
        </div>
    </div>
</template>

<script setup lang="ts">
    import type { AuthUser } from '@supabase/supabase-js'

    const user: AuthUser = useSupabaseUser()
    const switchLocalePath = useSwitchLocalePath()
    const toggleMenu = useState<boolean>('toggleMenu', () => true)
</script>
