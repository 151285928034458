<template>
    <nav class="flex flex-col justify-between h-full">
        <div class="flex flex-col gap-1">
            <NuxtLinkLocale
                to="/app"
                class="flex items-center px-3 py-3 text-sm whitespace-nowrap rounded-md w-full hover:bg-gray-200 hover:text-black aria-[current]:bg-black aria-[current]:text-white aria-[current]:hover:shadow-md"
            >
                <span class="material-symbols-outlined mr-2"> dashboard </span>
                <div v-if="toggleMenu">
                    {{ $t('Tso.app.menu.dashboard') }}
                </div>
            </NuxtLinkLocale>
            <hr class="my-6" />
            <LinkMenu to="/app/cars" icon="local_shipping"> {{ $t('Tso.app.menu.trucks') }} </LinkMenu>
            <LinkMenu to="/app/trailers" icon="rv_hookup"> {{ $t('Tso.app.menu.trailers') }} </LinkMenu>
            <LinkMenu to="/app/solo" icon="airport_shuttle"> {{ $t('Tso.app.menu.solo') }} </LinkMenu>
            <hr class="my-6" />
            <LinkMenu to="/app/drivers" icon="group"> {{ $t('Tso.app.menu.drivers') }} </LinkMenu>
        </div>
        <div class="flex flex-col gap-1">
            <LinkMenu to="/app/employees" icon="group"> {{ $t('Tso.app.menu.employees') }} </LinkMenu>
            <LinkMenu to="/app/settings" icon="settings"> {{ $t('Tso.app.menu.settings') }} </LinkMenu>
            <button
                class="flex items-center px-3 py-3 text-sm rounded-md w-full whitespace-nowrap hover:bg-gray-200 hover:text-black"
                @click="signOut"
            >
                <span class="material-symbols-outlined mr-2">logout</span>
                <span v-if="toggleMenu">{{ $t('Tso.app.menu.logout') }}</span>
            </button>
        </div>
    </nav>
</template>

<script setup lang="ts">
    import { useUser } from '#imports'

    const { signOut } = useUser()
    const toggleMenu = useState('toggleMenu')
</script>
