<template>
    <nav class="md:hidden fixed left-0 bottom-0 flex justify-between bg-white w-full px-4 py-2 z-50">
        <NuxtLinkLocale to="/app" class="flex flex-col justify-center items-center text-[10px]">
            <Icon name="dashboard" /> {{ $t('Tso.app.menu.dashboard') }}
        </NuxtLinkLocale>
        <NuxtLinkLocale to="/app/cars" class="flex flex-col justify-center items-center text-[10px]">
            <Icon name="local_shipping" /> {{ $t('Tso.app.menu.trucks') }}
        </NuxtLinkLocale>
        <NuxtLinkLocale to="/app/trailers" class="flex flex-col justify-center items-center text-[10px]">
            <Icon name="rv_hookup" /> {{ $t('Tso.app.menu.trailers') }}
        </NuxtLinkLocale>
        <NuxtLinkLocale to="/app/solo" class="flex flex-col justify-center items-center text-[10px]">
            <Icon name="airport_shuttle" /> {{ $t('Tso.app.menu.solo') }}
        </NuxtLinkLocale>
        <NuxtLinkLocale to="/app/drivers" class="flex flex-col justify-center items-center text-[10px]">
            <Icon name="group" /> {{ $t('Tso.app.menu.drivers') }}
        </NuxtLinkLocale>
        <button class="flex flex-col justify-center items-center text-[10px]" @click="signOut">
            <Icon name="logout" />
            {{ $t('Tso.app.menu.logout') }}
        </button>
    </nav>
</template>
<script setup lang="ts">
    const { signOut } = useUser()
</script>
