<template>
    <NuxtLinkLocale
        class="flex items-center px-3 py-3 text-sm whitespace-nowrap rounded-md w-full hover:bg-gray-200 hover:text-black aria-[current]:bg-black aria-[current]:text-white aria-[current]:hover:shadow-md"
    >
        <Icon v-if="icon" :name="icon" class="mr-2" />

        <div v-if="toggleMenu">
            <slot />
        </div>
    </NuxtLinkLocale>
</template>

<script setup lang="ts">
    interface Props {
        icon?: string
    }

    defineProps<Props>()

    const toggleMenu = useState('toggleMenu')
</script>

<style scoped>
    .router-link-active {
        @apply bg-black text-white;
    }
</style>
